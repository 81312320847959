import { Reducer } from 'redux'
import { INDICACAO, TYPES } from './types'
import { AnyAction } from 'models/types'

const INITIAL_STATE: INDICACAO = {
  filtros: {
    numeroAdesao: '',
    cpf: '',
    dataInicial: '',
    dataFinal: '',
    lojaId: '',
    centroCustoId: ''
  },
  pagination: {
    page: 0,
    pageSize: 10,
    totalRegistros: 0
  },
  tabelaIndicacao: [],
  equipes: [],
  centros: [],
  modalEdicaoIndicacao: {
    numeroAdesao: '',
    cpf: '',
    flagAtualizado: false,
    flagManual: false,
    dataProposta: '',
    usuarioBanco: null,
    usuarioBancoId: '',
    loja: null,
    lojaId: '',
    centroCusto: null,
    centroCustoId: '',
    banco: null,
    bancoId: '',
    tipoOperacao: null,
    tipoOperacaoId: '',
    statusProposta: null,
    statusPropostaId: '',
    digitador: null,
    digitadorId: '',
    id: '',
    ativo: false,
    usuario: null,
    idUsuarioCriacao: '',
    dataCriacao: '',
    usuarioAlteracao: null,
    idUsuarioUltimaAlteracao: '',
    dataUltimaAlteracao: ''
  },
  isOpenModalEdicao: false,
  showConfirmacaoExclusao: false,
  optionsSelect: {
    centros: [],
    centrosModal: [],
    equipes: [],
    bancos: [],
    status: [],
    tiposOperacao: [],
    usuariosBanco: []
  },
  modalEdicaoIndicacaoInvalids: {
    bancoId: false,
    cpf: false,
    dataProposta: false,
    numeroAdesao: false,
    propostaIndicacaoId: false,
    statusPropostaId: false,
    tipoOperacaoId: false,
    usuarioBancoId: false,
    lojaId: false,
    centroCustoId: false
  },
  itemSelectedId: ''
}

const reducer: Reducer<INDICACAO, AnyAction> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TYPES.SET_FILTROS:
      return {
        ...state,
        filtros: { ...state.filtros, [action.field]: action.value }
      }
    case TYPES.CLEAN_FILTROS:
      return {
        ...state,
        filtros: INITIAL_STATE.filtros
      }
    case TYPES.SET_EQUIPES_OPTIONS:
      return {
        ...state,
        equipes: action.value
      }
    case TYPES.SET_TABELA_INDICACAO:
      return {
        ...state,
        tabelaIndicacao: action.value
      }
    case TYPES.CLEAN_TABELA_INDICACAO:
      return {
        ...state,
        tabelaIndicacao: INITIAL_STATE.tabelaIndicacao
      }
    case TYPES.SET_PAGINATION:
      return {
        ...state,
        pagination: action.value
      }
    case TYPES.TOGGLE_MODAL_EDICAO_INDICACAO_DISPATCH:
      return {
        ...state,
        isOpenModalEdicao: action.value
      }
    case TYPES.TOGGLE_MODAL_EDICAO_INDICACAO:
      return {
        ...state,
        isOpenModalEdicao: action.value
      }
    case TYPES.SET_INFOS_MODAL_EDICAO_INDICACAO:
      return {
        ...state,
        modalEdicaoIndicacao: action.value
      }
    case TYPES.CLEAN_INFOS_MODAL_EDICAO_INDICACAO:
      return {
        ...state,
        modalEdicaoIndicacao: INITIAL_STATE.modalEdicaoIndicacao
      }
    case TYPES.TOGGLE_MODAL_EXCLUSAO_DISPATCH:
      return {
        ...state,
        showConfirmacaoExclusao: action.value
      }
    case TYPES.SET_OPTIONS_SELECTS:
      return {
        ...state,
        optionsSelect: {
          ...state.optionsSelect,
          [action.field]: action.value
        }
      }
    case TYPES.SET_EQUIPES:
      return {
        ...state,
        equipes: action.value
      }
    case TYPES.SET_INVALIDS:
      return {
        ...state,
        modalEdicaoIndicacaoInvalids: {
          ...state.modalEdicaoIndicacaoInvalids,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_INVALIDS:
      return {
        ...state,
        modalEdicaoIndicacaoInvalids: INITIAL_STATE.modalEdicaoIndicacaoInvalids
      }
    case TYPES.SET_ITEM_SELECTED_ID_DISPATCH:
      return {
        ...state,
        itemSelectedId: action.value
      }
    default:
      return state
  }
}

export default reducer
