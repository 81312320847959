import { Reducer } from 'redux'
import { CREDENCIAIS, TYPES } from './types'
import { AnyAction } from 'models/types'

const INITIAL_STATE: CREDENCIAIS = {
  usuariosInstituicaoBmg: [],
  usuariosInstituicaoItau: [],
  bmg: {
    usuario: '',
    senha: ''
  },
  itau: {
    usuario: ''
  },
  pan: {
    usuario: '',
    senha: ''
  },
  master: {
    usuario: '',
    senha: ''
  },
  credenciaisAgente: {
    senha: '',
    usuarioAgente: ''
  },
  usuariosInstituicaoCrefaz: [],
  crefaz: {
    usuarioInstituicao: ''
  }
}

const reducer: Reducer<CREDENCIAIS, AnyAction> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TYPES.SET_USUARIOS_INSTITUICAO_BMG:
      return {
        ...state,
        usuariosInstituicaoBmg: action.value
      }
    case TYPES.SET_USUARIOS_INSTITUICAO_ITAU:
      return {
        ...state,
        usuariosInstituicaoItau: action.value
      }
    case TYPES.SET_LOGIN_BMG:
      return {
        ...state,
        bmg: { ...state.bmg, [action.field]: action.value }
      }
    case TYPES.SET_LOGIN_ITAU:
      return {
        ...state,
        itau: { ...state.itau, [action.field]: action.value }
      }
    case TYPES.SET_LOGIN_PAN:
      return {
        ...state,
        pan: { ...state.pan, [action.field]: action.value }
      }
    case TYPES.SET_LOGIN_MASTER:
      return {
        ...state,
        master: { ...state.master, [action.field]: action.value }
      }
    case TYPES.CLEAN_LOGIN_MASTER:
      return {
        ...state,
        master: INITIAL_STATE.master
      }
    case TYPES.CLEAN_LOGIN_PAN:
      return {
        ...state,
        pan: INITIAL_STATE.pan
      }
    case TYPES.CLEAN_LOGIN_BMG:
      return {
        ...state,
        bmg: INITIAL_STATE.bmg
      }
    case TYPES.CLEAN_LOGIN_ITAU:
      return {
        ...state,
        itau: INITIAL_STATE.itau
      }
    case TYPES.SET_CREDENCIAIS_AGENTE_FIELDS:
      return {
        ...state,
        credenciaisAgente: {
          ...state.credenciaisAgente,
          [action.field]: action.value
        }
      }
    case TYPES.SET_CREDENCIAIS_AGENTE:
      return {
        ...state,
        credenciaisAgente: action.value
      }
    case TYPES.CLEAN_CREDENCIAIS_AGENTE:
      return {
        ...state,
        credenciaisAgente: INITIAL_STATE.credenciaisAgente
      }
    case TYPES.SET_USUARIOS_INSTITUICAO_CREFAZ:
      return {
        ...state,
        usuariosInstituicaoCrefaz: action.value
      }
    case TYPES.SET_CREDENCIAL_CREFAZ_FIELDS:
      return {
        ...state,
        crefaz: { ...state.crefaz, [action.field]: action.value }
      }
    case TYPES.CLEAN_CREDENCIAL_CREFAZ:
      return {
        ...state,
        crefaz: INITIAL_STATE.crefaz
      }
    default:
      return state
  }
}
export default reducer
