import { Reducer } from 'redux'
import { UPLOAD_ARQUIVOS, TYPES } from './types'
import { AnyAction } from 'models/types'

const INITIAL_STATE: UPLOAD_ARQUIVOS = {
  activeTab: 'search',
  isCreating: false,
  search: {
    filtro: null,
    pesquisa: ''
  },
  pagination: {
    page: 0,
    pageSize: 10,
    totalRegistros: 0
  },
  uploads: [],
  tipos: [],
  showConfirmacaoExclusao: false,
  showConfirmacaoParams: '',
  invalids: {
    tipoArquivo: false,
    uploadedFile: false
  },
  register: {
    tipoArquivo: '',
    uploadedFile: null,
    nomeArquivo: ''
  },
  arquivo: {
    id: '',
    nomeArquivo: '',
    descricaoTipoArquivo: '',
    nomeUsuario: '',
    dataUpload: '',
    status: ''
  }
}

const reducer: Reducer<UPLOAD_ARQUIVOS, AnyAction> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TYPES.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.value }
    case TYPES.SET_ALL_UPLOAD:
      return { ...state, uploads: action.value }
    case TYPES.SET_PESQUISA:
      return {
        ...state,
        search: { ...state.search, [action.field]: action.value }
      }
    case TYPES.SET_PAGINACAO:
      return { ...state, pagination: action.value }
    case TYPES.SET_TIPOS_DE_ARQUIVOS:
      return { ...state, tipos: action.value }
    case TYPES.TOGGLE_MODAL_EXCLUSAO_DISPATCH:
      return {
        ...state,
        showConfirmacaoExclusao: action.value
      }
    case TYPES.SET_ITEM_SELECTED_ID_DISPATCH:
      return {
        ...state,
        showConfirmacaoParams: action.value
      }
    case TYPES.SET_REGISTER_FIELDS:
      return {
        ...state,
        register: { ...state.register, [action.field]: action.value }
      }
    case TYPES.SET_REGISTER:
      return {
        ...state,
        register: action.value
      }
    case TYPES.CLEAN_REGISTER:
      return { ...state, register: INITIAL_STATE.register }

    case TYPES.SET_INVALIDS:
      return {
        ...state,
        invalids: { ...state.invalids, [action.field]: action.value }
      }
    case TYPES.CLEAN_INVALIDS:
      return {
        ...state,
        invalids: INITIAL_STATE.invalids
      }
    case TYPES.CLEAN_SEARCH:
      return { ...state, search: INITIAL_STATE.search }
    case TYPES.SET_ARQUIVO:
      return { ...state, arquivo: action.value }
    case TYPES.CLEAN_ARQUIVO:
      return { ...state, arquivo: INITIAL_STATE.arquivo }
    case TYPES.SET_CREATING:
      return { ...state, isCreating: action.value }
    default:
      return state
  }
}

export default reducer
