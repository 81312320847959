import NotificacaoSimulacao from 'components/NotificacaoSimulacao'
import { createRoot } from 'react-dom/client'

function updateBadge(index: number, count: number) {
  const badge = document.getElementById(`badge-simulacao-${index}`)
  if (badge) {
    badge.textContent = `${count}`
  }
}

function renderNotification(
  container: HTMLElement,
  message: string,
  index: number
) {
  const notificationElement = document.createElement('div')
  container.appendChild(notificationElement)

  const root = createRoot(notificationElement)
  root.render(
    <NotificacaoSimulacao
      message={message}
      onClose={() => {
        root.unmount()
        container.removeChild(notificationElement)

        const updatedCount = container.children.length
        updateBadge(index, updatedCount)
      }}
    />
  )
}

export function handlerErrorSimulationService(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  index: number,
  defaultMessage?: string
) {
  const errors: string[] = []

  if (
    (error?.message || (error?.messages?.length > 0 && error?.messages)) &&
    !error?.stack
  ) {
    if (error?.messages?.length > 0) {
      errors.push(...error.messages)
    } else {
      errors.push(error.message)
    }
  } else {
    errors.push(defaultMessage || 'Não foi possível!')
  }

  const container = document.getElementById(`notificacao-simulacao-${index}`)
  if (!container) {
    console.warn('Notification container not found')
    return
  }

  const existingNotificationsCount = container.children.length

  errors.forEach((message) => {
    renderNotification(container, message, index)
  })

  const totalNotificationsCount = existingNotificationsCount + errors.length
  updateBadge(index, totalNotificationsCount)
}
