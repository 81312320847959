/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Props as SelectProps, StylesConfig } from 'react-select'
import Creatable from 'react-select/creatable'

interface CustomSelectProps extends SelectProps {
  accessorLabel?: string
  accessorValue?: string
  options: any[]
  onChange?: (e: any) => void
  value: any
  invalid?: boolean
  disabled?: boolean
  onInputChange?: (e: string) => string
  onCreateOption?: (e: string) => void
  refSelect?: any
  placeholder?: any
  isMulti?: boolean
  isClearable?: boolean
}

const CreatableSelect = ({
  placeholder = 'Selecione',
  accessorValue = 'id',
  accessorLabel = 'label',
  options,
  value,
  onChange,
  invalid,
  disabled,
  onInputChange,
  onCreateOption,
  refSelect: ref,
  isClearable,
  ...props
}: CustomSelectProps) => {
  const [optionsSelect, setOptionsSelect] = useState<any[]>()
  const [optionSelected, setOptionSelected] = useState<any>()
  const [inputValue, setInputValue] = useState<string | null>('')

  useEffect(() => {
    setOptionsSelect([])
    options?.map((option: any) =>
      setOptionsSelect((state: any) => [
        ...state,
        { value: option[accessorValue], label: option[accessorLabel] }
      ])
    )
  }, [options, accessorValue, accessorLabel])

  useEffect(() => {
    if (!props.isMulti) {
      if (!value || value === '0') {
        setOptionSelected(null)
      } else {
        const optionSelected = optionsSelect?.find(
          (option: any) => option.value === value
        )
        if (optionSelected) {
          setOptionSelected(optionSelected)
        } else {
          setOptionSelected(null)
        }
      }
    } else {
      if (value) {
        const optionsSelecteds: any[] = []
        value?.map((e: any) => {
          const index = optionsSelect?.findIndex(
            (option: any) => option.value === e
          )
          if ((index || index === 0) && index !== -1 && optionsSelect) {
            optionsSelecteds.push(optionsSelect[index])
          }
        })
        setOptionSelected(optionsSelecteds)
      }
    }
  }, [optionsSelect, props.isMulti, value])

  function onSelect(option: any) {
    setOptionSelected(option)
  }

  const customStyles: StylesConfig = {
    control: (provided, state) => {
      const borderRadius = '15px'

      return {
        ...provided,
        borderRadius,
        borderColor: invalid
          ? '#dc3545'
          : state.isFocused
          ? '#2684FF'
          : '#ced4da',
        boxShadow: state.isFocused
          ? invalid
            ? '0 0 0 0.25rem rgb(220 53 69 / 25%)'
            : '0 0 0 0.25rem rgb(13 110 253 / 25%)'
          : '',
        ':hover': {
          borderColor: invalid
            ? '#dc3545'
            : state.isFocused
            ? '#2684FF'
            : '#ced4da'
        }
      }
    }
  }

  return (
    <Creatable
      ref={ref}
      isClearable={isClearable}
      styles={customStyles}
      options={optionsSelect}
      placeholder={placeholder}
      value={optionSelected}
      onChange={(e: any) => {
        if (onChange) {
          if (!props.isMulti) {
            onChange(e?.value || null)
          } else {
            const values: any[] = []
            e.map((value: any) => {
              values.push(value.value)
            })
            onChange(values)
          }
        }
        onSelect(e)
      }}
      noOptionsMessage={() => {
        return <span>Sem opções</span>
      }}
      isDisabled={disabled}
      formatCreateLabel={(e: any) => `Adicionar: ${e}`}
      onInputChange={(e: any) => {
        onInputChange && setInputValue(onInputChange(e))
      }}
      inputValue={inputValue || ''}
      onCreateOption={(e: any) => {
        onCreateOption?.(e)
      }}
      {...props}
    />
  )
}

export { CreatableSelect }
