import { Reducer } from 'redux'
import { PRODUTO, TYPES } from './types'
import { AnyAction } from 'models/types'

const INITIAL_STATE: PRODUTO = {
  activeTab: 'search',
  search: {
    filtro: null,
    pesquisa: ''
  },
  selects: {
    produtos: [],
    instituicoes: [],
    convenios: [],
    tipoOperacao: [],
    tipoFormalizacao: [],
    taxas: [],
    tabelas: [],
    beneficios: []
  },
  register: {
    id: '',
    produtoId: '',
    bancoId: '',
    convenioId: '',
    tipoOperacaoId: '',
    tipoFormalizacaoId: '',
    margemMin: null,
    margemMax: null,
    idadeDe: null,
    idadeAte: null,
    mesesDe: null,
    mesesAte: null,
    diasDe: null,
    diasAte: null,
    prazo: null,
    trocoMin: null,
    trocoMax: null,
    pmtPaga: null,
    saldoDevedorMin: null,
    saldoDevedorMax: null,
    valorContrato: null,
    cartaoInstituicaoId: null,
    taxas: [],
    bancosNaoPortados: [],
    descricaoGrupoBeneficio: null,
    beneficios: [],
    tabelas: []
  },
  pagination: {
    page: 0,
    pageSize: 10,
    totalRegistros: 0
  },
  invalids: {
    id: false,
    produtoId: false,
    bancoId: false,
    convenioId: false,
    tipoOperacaoId: false,
    tipoFormalizacaoId: false,
    margemMin: false,
    margemMax: false,
    idadeDe: false,
    idadeAte: false,
    mesesDe: false,
    mesesAte: false,
    diasDe: false,
    diasAte: false,
    prazo: false,
    trocoMin: false,
    trocoMax: false,
    pmtPaga: false,
    saldoDevedorMin: false,
    saldoDevedorMax: false,
    valorContrato: false,
    cartaoInstituicaoId: false,
    taxas: false,
    bancosNaoPortados: false,
    descricaoGrupoBeneficio: false,
    beneficios: [],
    tabelas: []
  },
  visualizacao: false,
  listaParametrizacao: [],
  itemSelectedId: '',
  showConfirmacaoExclusao: false,
  cardsState: {
    bancosNaoPortados: false,
    cartao: false,
    gruposBeneficio: false,
    idade: false,
    pmtPaga: false,
    saldoDevedor: false,
    tabelasServicos: false,
    taxaOriginal: false,
    trocoMinimo: false,
    valorContrato: false,
    valorMargem: false
  }
}

const reducer: Reducer<PRODUTO, AnyAction> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TYPES.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.value }
    case TYPES.SET_ITEM_SELECTED_ID:
      return {
        ...state,
        itemSelectedId: action.value
      }
    case TYPES.TOGGLE_MODAL_EXCLUSAO:
      return {
        ...state,
        showConfirmacaoExclusao: action.value
      }
    case TYPES.SET_PESQUISA:
      return {
        ...state,
        search: { ...state.search, [action.field]: action.value }
      }
    case TYPES.SET_PARAMETRIZACAO_LIST:
      return {
        ...state,
        listaParametrizacao: action.value
      }
    case TYPES.SET_REGISTER:
      return {
        ...state,
        register: action.value
      }
    case TYPES.SET_VISUALIZACAO:
      return {
        ...state,
        visualizacao: action.value
      }
    case TYPES.SET_PAGINATION:
      return {
        ...state,
        pagination: action.value
      }
    case TYPES.SET_INVALIDS:
      return {
        ...state,
        invalids: { ...state.invalids, [action.field]: action.value }
      }
    case TYPES.SET_INVALIDS_ARRAY: {
      const invalids = JSON.parse(JSON.stringify({ ...state.invalids }))
      invalids[action.field][action.index][action.fieldComplementary] = true
      return {
        ...state,
        invalids: invalids
      }
    }
    case TYPES.CLEAN_INVALIDS: {
      const tabelasInvalids = [...state.invalids.tabelas]
      tabelasInvalids.forEach((tabela) => {
        tabela.rangePrazoFinal = false
        tabela.rangePrazoInicial = false
        tabela.tabelasComissao = false
      })
      return {
        ...state,
        invalids: {
          id: false,
          produtoBancoId: false,
          bancoId: false,
          convenioBancoId: false,
          tipoOperacaoBancoId: false,
          tipoFormalizacaoBancoId: false,
          margemMin: false,
          margemMax: false,
          idadeDe: false,
          idadeAte: false,
          mesesDe: false,
          mesesAte: false,
          diasDe: false,
          diasAte: false,
          prazo: false,
          trocoMin: false,
          trocoMax: false,
          pmtPaga: false,
          saldoDevedorMin: false,
          saldoDevedorMax: false,
          valorContrato: false,
          cartaoInstituicaoId: false,
          taxas: false,
          bancosNaoPortados: false,
          descricaoGrupoBeneficio: false,
          beneficios: [],
          tabelas: tabelasInvalids
        }
      }
    }
    case TYPES.CLEAN_REGISTER:
      return {
        ...state,
        register: {
          id: '',
          produtoBancoId: '',
          bancoId: '',
          convenioBancoId: '',
          tipoOperacaoBancoId: '',
          tipoFormalizacaoBancoId: '',
          margemMin: null,
          margemMax: null,
          idadeDe: null,
          idadeAte: null,
          mesesDe: null,
          mesesAte: null,
          diasDe: null,
          diasAte: null,
          prazo: null,
          trocoMin: null,
          trocoMax: null,
          pmtPaga: null,
          saldoDevedorMin: null,
          saldoDevedorMax: null,
          valorContrato: null,
          cartaoInstituicaoId: null,
          taxas: [],
          bancosNaoPortados: [],
          descricaoGrupoBeneficio: null,
          beneficios: [],
          tabelas: []
        }
      }
    case TYPES.SET_OPTIONS_SELECT:
      return {
        ...state,
        selects: {
          ...state.selects,
          [action.field]: action.value
        }
      }
    case TYPES.ADD_TABELA_COMISSAO:
      return {
        ...state,
        invalids: {
          ...state.invalids,
          tabelas: [
            ...state.invalids.tabelas,
            {
              tabelasComissao: false,
              rangePrazoInicial: false,
              rangePrazoFinal: false
            }
          ]
        },
        register: {
          ...state.register,
          tabelas: [
            ...state.register.tabelas,
            {
              id: '',
              tabelasComissao: [],
              rangePrazoInicial: null,
              rangePrazoFinal: null
            }
          ]
        }
      }
    case TYPES.REMOVE_TABELA_COMISSAO: {
      const tabelas = [...state.register.tabelas]
      tabelas.splice(action.index, 1)
      return {
        ...state,
        register: {
          ...state.register,
          tabelas: tabelas
        }
      }
    }
    case TYPES.REMOVE_ALL_TABELA_COMISSAO:
      return {
        ...state,
        register: {
          ...state.register,
          tabelas: []
        }
      }
    case TYPES.TOGGLE_CARD:
      return {
        ...state,
        cardsState: {
          ...state.cardsState,
          [action.field]: action.value
        }
      }
    case TYPES.TOGGLE_ALL_CARDS:
      return {
        ...state,
        cardsState: {
          bancosNaoPortados: action.value,
          cartao: action.value,
          gruposBeneficio: action.value,
          idade: action.value,
          pmtPaga: action.value,
          saldoDevedor: action.value,
          tabelasServicos: action.value,
          taxaOriginal: action.value,
          trocoMinimo: action.value,
          valorContrato: action.value,
          valorMargem: action.value
        }
      }
    case TYPES.CLEAN_BANCOS_NAO_PORTADOS:
      return {
        ...state,
        register: {
          ...state.register,
          bancosNaoPortados: []
        }
      }
    case TYPES.CLEAN_CARTAO:
      return {
        ...state,
        register: {
          ...state.register,
          cartaoInstituicaoId: null
        }
      }
    case TYPES.CLEAN_GRUPOS_BENEFICIO:
      return {
        ...state,
        register: {
          ...state.register,
          beneficios: []
        }
      }
    case TYPES.CLEAN_IDADE:
      return {
        ...state,
        register: {
          ...state.register,
          idadeDe: null,
          idadeAte: null,
          mesesDe: null,
          mesesAte: null,
          diasDe: null,
          diasAte: null,
          prazo: null
        }
      }
    case TYPES.CLEAN_PMT_PAGA:
      return {
        ...state,
        register: {
          ...state.register,
          pmtPaga: null
        }
      }
    case TYPES.CLEAN_SALDO_DEVEDOR:
      return {
        ...state,
        register: {
          ...state.register,
          saldoDevedorMin: null,
          saldoDevedorMax: null
        }
      }
    case TYPES.CLEAN_TAXA_ORIGINAL:
      return {
        ...state,
        register: {
          ...state.register,
          taxas: []
        }
      }
    case TYPES.CLEAN_TROCO_MINIMO:
      return {
        ...state,
        register: {
          ...state.register,
          trocoMin: null,
          trocoMax: null
        }
      }
    case TYPES.CLEAN_VALOR_CONTRATO:
      return {
        ...state,
        register: {
          ...state.register,
          valorContrato: null
        }
      }
    case TYPES.CLEAN_VALOR_MARGEM:
      return {
        ...state,
        register: {
          ...state.register,
          margemMin: null,
          margemMax: null
        }
      }
    default:
      return state
  }
}

export default reducer
