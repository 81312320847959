import { Reducer } from 'redux'
import { maskDate } from 'util/masks'
import { PRODUCAO, TYPES } from './types'
import { AnyAction } from 'models/types'

const INITIAL_STATE: PRODUCAO = {
  filtros: {
    adesao: '',
    cpf: '',
    dataInicio: maskDate(Date.now() as unknown as string, 'YYYY-MM-DD'),
    dataFim: '',
    equipeId: '',
    digitadorId: ''
  },
  pagination: {
    page: 0,
    pageSize: 10,
    totalRegistros: 0
  },
  tabelaAcompanhamentoProducao: [],
  usuarios: [],
  equipes: [],
  modalPendenciasAnexoCrefaz: {
    cpf: '',
    propostaId: '',
    isOpen: false,
    register: {
      tipoArquivo: '',
      uploadedFile: null
    },
    tiposArquivo: [],
    anexos: [],
    paginacaoAnexos: {
      page: 0,
      pageSize: 10,
      totalRegistros: 0
    }
  }
}

const reducer: Reducer<PRODUCAO, AnyAction> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TYPES.SET_FILTROS:
      return {
        ...state,
        filtros: { ...state.filtros, [action.field]: action.value }
      }
    case TYPES.CLEAN_FILTROS:
      return {
        ...state,
        filtros: INITIAL_STATE.filtros
      }
    case TYPES.SET_USUARIOS_OPTIONS:
      return {
        ...state,
        usuarios: action.value
      }
    case TYPES.SET_EQUIPES_OPTIONS:
      return {
        ...state,
        equipes: action.value
      }
    case TYPES.SET_TABELA_ACOMPANHAMENTO_PRODUCAO:
      return {
        ...state,
        tabelaAcompanhamentoProducao: action.value
      }
    case TYPES.CLEAN_TABELA_ACOMPANHAMENTO_PRODUCAO:
      return {
        ...state,
        tabelaAcompanhamentoProducao: INITIAL_STATE.tabelaAcompanhamentoProducao
      }
    case TYPES.SET_PAGINATION:
      return {
        ...state,
        pagination: action.value
      }
    case TYPES.SET_MODAL_PENDENCIAS_ANEXO_CREFAZ_FIELDS:
      return {
        ...state,
        modalPendenciasAnexoCrefaz: {
          ...state.modalPendenciasAnexoCrefaz,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_MODAL_PENDENCIAS_ANEXO_CREFAZ:
      return {
        ...state,
        modalPendenciasAnexoCrefaz: {
          cpf: '',
          propostaId: '',
          isOpen: false,
          register: {
            tipoArquivo: '',
            uploadedFile: null
          },
          tiposArquivo: [],
          anexos: [],
          paginacaoAnexos: {
            page: 0,
            pageSize: 10,
            totalRegistros: 0
          }
        }
      }
    default:
      return state
  }
}

export default reducer
