import { Provider } from 'react-redux'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import GlobalStyle from 'styles/global'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import store from 'store'
import { createTheme, ThemeProvider } from '@mui/material'
import { ptBR } from '@mui/material/locale'
import history from 'util/history'
import FormularioNecessidade from 'components/FormularioNecessidade'
import CadastroSimplificado from 'components/CadastroSimplificado/CadastroSimplificado'
import ConfirmacaoCadastro from 'components/CadastroSimplificado/ConfirmacaoCadastro'
import AppRoutes from 'components/AppRoutes'

const theme = createTheme(ptBR)

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <HistoryRouter history={history}>
          <AppRoutes />
        </HistoryRouter>
        <ToastContainer />
        <GlobalStyle />
        <FormularioNecessidade />
        <ConfirmacaoCadastro />
        <CadastroSimplificado />
      </ThemeProvider>
    </Provider>
  )
}

export default App
