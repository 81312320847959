import {
  InformacoesChamadaDto,
  StatusChamadaEnum
} from 'models/atendimento/types'
import { SagaIterator, eventChannel } from 'redux-saga'
import {
  call,
  fork,
  put,
  all,
  takeEvery,
  select,
  take
} from 'redux-saga/effects'
import { LOGIN, TYPES } from './types'
import * as agendaController from 'controller/agendaController'
import * as atendimentoController from 'controller/atendimentoController'
import * as loginController from 'controller/loginController'
import * as loginActions from 'store/modules/login/actions'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import { APICore, setAuthorization } from 'helpers/api/apiCore'
import { toast } from 'react-toastify'
import { ApplicationState } from 'store'
import history from 'util/history'
import { handlerError } from 'util/handlerError'
import routes from 'routes'
import { PermissoesTela } from 'models/login/types'
import { maskPhoneWithDDD } from 'util/masks'
import moment from 'moment'
import { HubConnectionState } from '@microsoft/signalr'

const api = new APICore()

function* login(): SagaIterator {
  try {
    yield put(loginActions.setLoading(true))
    const { login }: LOGIN = yield select(
      (state: ApplicationState) => state.login
    )
    const responseCrm = yield call(loginController.loginCrm, {
      email: login.email,
      senha: login.senha,
      lembrar: login.lembrar
    })
    if (login.lembrar) {
      sessionStorage.setItem('email_login', login.email)
    } else {
      sessionStorage.removeItem('email_login')
    }
    yield put(loginActions.cleanLogin())
    setAuthorization(responseCrm?.data?.content?.token || null)
    api.isAuthenticated() && window.location.replace('/inicio')
  } catch (error) {
    handlerError(error, 'Não foi possível realizar o login!')
  } finally {
    yield put(loginActions.setLoading(false))
  }
}

function* logout(): SagaIterator {
  yield call(loginController.logoutSistema)
}

interface RecuperarAcessoProps {
  type: string
  id: string
}

function* recuperarAcesso(): SagaIterator {
  const { login }: LOGIN = yield select(
    (state: ApplicationState) => state.login
  )
  try {
    yield put(loginActions.setLoading(true))
    yield call(loginController.recuperarAcesso, login.email)
    yield put(loginActions.cleanLogin())
    toast.info(
      'O e-mail para redefinição de senha será enviado para o e-mail informado caso seja de algum usuário pré-cadastrado'
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loginActions.setLoading(false))
  }
}

function* validaGuidRecuperacao({ id }: RecuperarAcessoProps): SagaIterator {
  try {
    yield put(loginActions.setLoading(true))
    yield call(loginController.validaGuidRecuperacao, id)
  } catch (error) {
    handlerError(error)
    history.push('/Login')
  } finally {
    yield put(loginActions.setLoading(false))
  }
}

function* redefinirSenha({ id }: RecuperarAcessoProps): SagaIterator {
  const { recuperacao }: LOGIN = yield select(
    (state: ApplicationState) => state.login
  )
  try {
    yield put(loginActions.setLoading(true))
    yield call(loginController.redefinirSenha, id, recuperacao.senha)
    toast.success('Senha redefinida com sucesso!')
    history.push('/Login')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loginActions.setLoading(false))
  }
}

function* permissoesUsuario(): SagaIterator {
  try {
    if (api.isAuthenticated()) {
      yield put(loginActions.setLoadingPermissoes(true))
      yield put(loginActions.cleanPermissoesActionsUsuario())
      yield put(loginActions.cleanPermissoesUsuario())
      const response = yield call(loginController.permissoesUsuario)
      const rotas = routes?.filter((route) =>
        response?.data?.content?.permissoesTelas?.some(
          (routeRes: PermissoesTela) => routeRes.rota === route.path
        )
      )
      const rotasComAninhamentos = rotas?.filter((route) =>
        route.subPaths?.some((routeRes) =>
          response?.data?.content?.permissoesTelas?.some(
            (sub: PermissoesTela) => sub.rota === routeRes.path
          )
        )
      )
      rotasComAninhamentos?.forEach((route) => {
        const rotasAninhadasFiltradas = route?.subPaths?.filter(
          (test) =>
            response?.data?.content?.permissoesTelas?.some(
              (sub: PermissoesTela) => sub.rota === test.path
            ) || test.index
        )
        route.subPaths = rotasAninhadasFiltradas
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const actions: any = {}
      response?.data?.content?.permissoesTelas?.map(
        (permissao: PermissoesTela) => {
          actions[permissao.menu] = {
            autorizar: false,
            deletar: false,
            estornar: false,
            exportar: false,
            gravar: false,
            visualizar: false
          }
          permissao?.tipoOperacao?.map((tipo: string) => {
            switch (tipo) {
              case 'AUTORIZAR':
                actions[permissao.menu].autorizar = true
                break
              case 'GRAVAR':
                actions[permissao.menu].gravar = true
                break
              case 'ESTORNAR':
                actions[permissao.menu].estornar = true
                break
              case 'VISUALIZAR':
                actions[permissao.menu].visualizar = true
                break
              case 'EXPORTAR':
                actions[permissao.menu].exportar = true
                break
              case 'DELETAR':
                actions[permissao.menu].deletar = true
                break
              default:
                break
            }
          })
        }
      )
      yield put(loginActions.setPermissoesActionsUsuario(actions))
      yield put(loginActions.setPermissoesUsuario(rotas))
    }
  } catch (error) {
    handlerError(error, 'Não foi possível definir as permissões do usuário')
  } finally {
    yield put(loginActions.setLoadingPermissoes(false))
  }
}

function createHubChannelNotificacao(
  hubConnection: signalR.HubConnection,
  eventName: string
) {
  return eventChannel(() => {
    hubConnection.on(eventName, (body) => {
      toast.info(body.message, {
        autoClose: false
      })
    })

    if (hubConnection.state === HubConnectionState.Disconnected) {
      hubConnection
        .start()
        .catch((err) => console.error('Erro ao iniciar a conexão:', err))
    }

    return () => {
      hubConnection
        .stop()
        .catch((err) => console.error('Erro ao parar a conexão:', err))
    }
  })
}

function* initializeWebSocketsChannelNotificacao(): SagaIterator {
  try {
    const eventName = 'SendMessage'
    const hubConnection: signalR.HubConnection = yield call(
      agendaController.iniciarNotificacoes,
      eventName
    )
    const channel = yield call(
      createHubChannelNotificacao,
      hubConnection,
      eventName
    )
    while (true) {
      const event = yield take(channel)
      yield put(event)
    }
  } catch (error) {
    console.error(error)
  }
}

function createHubChannelRetornoDiscagemTelecom(
  hubConnection: signalR.HubConnection,
  eventName: string
) {
  return eventChannel(() => {
    hubConnection.on(eventName, (body: InformacoesChamadaDto) => {
      switch (body.statusChamada) {
        case 1:
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            StatusChamadaEnum.discando,
            'statusChamada',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            false,
            'emChamada',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            true,
            'modalDiscagem',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            body.telefones?.map((telefone) => {
              return maskPhoneWithDDD(telefone.ddd + telefone.numero)
            }),
            'numerosDiscados',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            '',
            'numeroChamada',
            body.cpfCliente
          )

          break
        case 2:
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            StatusChamadaEnum.atendida,
            'statusChamada',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            true,
            'emChamada',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            false,
            'modalDiscagem',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            [],
            'numerosDiscados',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            maskPhoneWithDDD(body.ddd + body.numero),
            'numeroChamada',
            body.cpfCliente
          )
          break
        case 3:
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            true,
            'emChamada',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            false,
            'modalDiscagem',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            [],
            'numerosDiscados',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            maskPhoneWithDDD(body.ddd + body.numero),
            'numeroChamada',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            body.callId,
            'callId',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            moment().add(1, 'days').format('YYYY-MM-DD'),
            'dataAgendamento',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            moment().format('HH:mm'),
            'horarioAgendamento',
            body.cpfCliente
          )
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            StatusChamadaEnum.finalizada,
            'statusChamada',
            body.cpfCliente
          )
          break
        case 4:
          atendimentoActions.setInfosChamadaContatoByCpfDispatch(
            body.callId,
            'callId',
            body.cpfCliente
          )
          if (body.tipoLigacao === '1') {
            atendimentoActions.setInfosChamadaContatoByCpfDispatch(
              maskPhoneWithDDD(body.ddd + body.numero),
              'numeroChamada',
              body.cpfCliente
            )
            atendimentoActions.setInfosChamadaContatoByCpfDispatch(
              true,
              'emChamada',
              body.cpfCliente
            )
            atendimentoActions.setInfosChamadaContatoByCpfDispatch(
              false,
              'modalDiscagem',
              body.cpfCliente
            )
            atendimentoActions.setInfosChamadaContatoByCpfDispatch(
              [],
              'numerosDiscados',
              body.cpfCliente
            )
            atendimentoActions.setInfosChamadaContatoByCpfDispatch(
              moment().add(1, 'days').format('YYYY-MM-DD'),
              'dataAgendamento',
              body.cpfCliente
            )
            atendimentoActions.setInfosChamadaContatoByCpfDispatch(
              moment().format('HH:mm'),
              'horarioAgendamento',
              body.cpfCliente
            )
            atendimentoActions.setInfosChamadaContatoByCpfDispatch(
              StatusChamadaEnum.finalizada,
              'statusChamada',
              body.cpfCliente
            )
          }
          if (body.tabulacao && body.tipoLigacao === '2') {
            atendimentoActions.setInfosChamadaContatoByCpfDispatch(
              body.tabulacao,
              'tabulacaoChamada',
              body.cpfCliente
            )
            atendimentoActions.tabularAtendimentoDispatchSaga(
              body.cpfCliente,
              maskPhoneWithDDD(body.ddd + body.numero),
              true
            )
          }
          break
        default:
          break
      }
    })

    if (hubConnection.state === HubConnectionState.Disconnected) {
      hubConnection
        .start()
        .catch((err) => console.error('Erro ao iniciar a conexão:', err))
    }

    return () => {
      hubConnection
        .stop()
        .catch((err) => console.error('Erro ao parar a conexão:', err))
    }
  })
}

function* initializeWebSocketsChannelRetornoDiscagemTelecom(): SagaIterator {
  try {
    const eventName = 'RetornaInformacoesChamada'
    const hubConnection: signalR.HubConnection = yield call(
      atendimentoController.iniciarRetornoDiscagemTelecom,
      eventName
    )
    const channel = yield call(
      createHubChannelRetornoDiscagemTelecom,
      hubConnection,
      eventName
    )
    while (true) {
      const event = yield take(channel)
      yield put(event)
    }
  } catch (error) {
    console.error(error)
  }
}

export function* watchLogin() {
  yield takeEvery(TYPES.LOGIN_SAGA, login)
}

export function* watchLogout() {
  yield takeEvery(TYPES.LOGOUT_SAGA, logout)
}

export function* watchRecuperarAcesso() {
  yield takeEvery(TYPES.RECUPERAR_ACESSO_SAGA, recuperarAcesso)
}

export function* watchValidaGuidRecuperacao() {
  yield takeEvery(TYPES.VALIDA_GUID_RECUPERACAO_SAGA, validaGuidRecuperacao)
}

export function* watchRedefinirSenha() {
  yield takeEvery(TYPES.REDEFINIR_SENHA_SAGA, redefinirSenha)
}

export function* watchPermissoesUsuario() {
  yield takeEvery(TYPES.PERMISSOES_USUARIO_SAGA, permissoesUsuario)
}

export function* watchInitializeWebSocketsChannelNotificacao() {
  yield takeEvery(
    TYPES.INICIAR_NOTIFICACOES_SAGA,
    initializeWebSocketsChannelNotificacao
  )
}

export function* watchInitializeWebSocketsChannelRetornoDiscagemTelecom() {
  yield takeEvery(
    TYPES.INICIAR_RETORNO_DISCAGEM_TELECOM_SAGA,
    initializeWebSocketsChannelRetornoDiscagemTelecom
  )
}

function* loginSaga() {
  yield all([
    fork(watchLogin),
    fork(watchLogout),
    fork(watchRecuperarAcesso),
    fork(watchValidaGuidRecuperacao),
    fork(watchRedefinirSenha),
    fork(watchPermissoesUsuario),
    fork(watchInitializeWebSocketsChannelNotificacao),
    fork(watchInitializeWebSocketsChannelRetornoDiscagemTelecom)
  ])
}

export default loginSaga
