import { Reducer } from 'redux'
import { TABULACAO, TYPES } from './types'
import { AnyAction } from 'models/types'

const INITIAL_STATE: TABULACAO = {
  activeTab: 'search',
  search: {
    filtro: null,
    pesquisa: ''
  },
  tabulacoes: [],
  polaridades: [],
  localizacoes: [],
  agendamentos: [],
  register: {
    id: '',
    descricao: '',
    periodoAgendamentoId: '',
    condenarTelefone: false,
    finalizarAtendimento: false,
    localizacaoId: '',
    observacoes: '',
    polaridadeId: '',
    pularParaProximoTelefone: false,
    retirarClienteCampanha: false,
    mensagemSms: '',
    enviarSms: false
  },
  pagination: {
    page: 0,
    pageSize: 10,
    totalRegistros: 0
  },
  invalids: {
    id: false,
    descricao: false,
    periodoAgendamentoId: false,
    bloquearAtendimentoDurantePeriodo: false,
    condenarTelefone: false,
    finalizarAtendimento: false,
    localizacaoId: false,
    observacoes: false,
    polaridadeId: false,
    pularParaProximoTelefone: false,
    retirarClienteCampanha: false,
    enviarSms: false,
    mensagemSms: ''
  },
  visualizacao: false,
  itemSelectedId: '',
  showConfirmacaoExclusao: false
}

const reducer: Reducer<TABULACAO, AnyAction> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TYPES.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.value }
    case TYPES.SET_ITEM_SELECTED_ID:
      return {
        ...state,
        itemSelectedId: action.value
      }
    case TYPES.TOGGLE_MODAL_EXCLUSAO:
      return {
        ...state,
        showConfirmacaoExclusao: action.value
      }
    case TYPES.SET_PESQUISA:
      return {
        ...state,
        search: { ...state.search, [action.field]: action.value }
      }
    case TYPES.SET_REGISTER_FIELDS:
      return {
        ...state,
        register: { ...state.register, [action.field]: action.value }
      }
    case TYPES.SET_REGISTER:
      return {
        ...state,
        register: action.value
      }
    case TYPES.SET_VISUALIZACAO:
      return {
        ...state,
        visualizacao: action.value
      }
    case TYPES.SET_PAGINATION:
      return {
        ...state,
        pagination: action.value
      }
    case TYPES.SET_INVALIDS:
      return {
        ...state,
        invalids: { ...state.invalids, [action.field]: action.value }
      }
    case TYPES.CLEAN_INVALIDS:
      return {
        ...state,
        invalids: INITIAL_STATE.invalids
      }
    case TYPES.CLEAN_REGISTER:
      return { ...state, register: INITIAL_STATE.register }
    case TYPES.SET_TABULACOES:
      return { ...state, tabulacoes: action.value }
    case TYPES.SET_POLARIDADES:
      return { ...state, polaridades: action.value }
    case TYPES.SET_LOCALIZACOES:
      return { ...state, localizacoes: action.value }
    case TYPES.SET_AGENDAMENTOS:
      return { ...state, agendamentos: action.value }
    default:
      return state
  }
}

export default reducer
