import { Reducer } from 'redux'
import { GRUPOS, TYPES } from './types'
import { AnyAction } from 'models/types'

const INITIAL_STATE: GRUPOS = {
  activeTab: 'search',
  search: {
    filtro: null,
    pesquisa: ''
  },
  pagination: {
    page: 0,
    pageSize: 10,
    totalRegistros: 0
  },
  grupos: [],
  lojas: [],
  limiteMaxRegistros: 500,
  invalids: {
    id: false,
    descricao: false,
    lojas: false
  },
  register: {
    id: '',
    descricao: '',
    lojas: []
  },
  showConfirmacaoExclusao: false,
  itemSelectedId: '',
  visualizacao: false
}

const reducer: Reducer<GRUPOS, AnyAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.value
      }

    case TYPES.TOGGLE_MODAL_EXCLUSAO:
      return {
        ...state,
        showConfirmacaoExclusao: action.value
      }

    case TYPES.SET_ITEM_SELECTED_ID:
      return {
        ...state,
        itemSelectedId: action.value
      }

    case TYPES.SET_PESQUISA:
      return {
        ...state,
        search: { ...state.search, [action.field]: action.value }
      }
    case TYPES.SET_PAGINATION:
      return {
        ...state,
        pagination: action.value
      }

    case TYPES.SET_VISUALIZACAO:
      return {
        ...state,
        visualizacao: action.value
      }

    case TYPES.SET_GRUPOS:
      return { ...state, grupos: action.value }

    case TYPES.SET_LOJAS:
      return { ...state, lojas: action.value }

    case TYPES.SET_REGISTER_FIELDS:
      return {
        ...state,
        register: { ...state.register, [action.field]: action.value }
      }
    case TYPES.SET_REGISTER:
      return {
        ...state,
        register: action.value
      }
    case TYPES.SET_INVALIDS:
      return {
        ...state,
        invalids: { ...state.invalids, [action.field]: action.value }
      }
    case TYPES.CLEAN_INVALIDS:
      return {
        ...state,
        invalids: INITIAL_STATE.invalids
      }
    case TYPES.CLEAN_REGISTER:
      return { ...state, register: INITIAL_STATE.register }

    case TYPES.CLEAN_SEARCH:
      return { ...state, search: INITIAL_STATE.search }

    default:
      return state
  }
}

export default reducer
