import React from 'react'
import { Container, Message, ButtonClose } from './style'

interface Props {
  message: string
  onClose: () => void
}

const NotificacaoSimulacao = ({ message, onClose }: Props) => {
  return (
    <Container>
      <Message>{message}</Message>
      <ButtonClose onClick={onClose} aria-label="Fechar notificação">
        x
      </ButtonClose>{' '}
      <hr />
    </Container>
  )
}

export default NotificacaoSimulacao
