import { Reducer } from 'redux'
import { AGENDA, TYPES } from './types'
import { AnyAction } from 'models/types'

const INITIAL_STATE: AGENDA = {
  activeTab: 'calendario',
  diaDetalhamento: '',
  agendamentoSelecionado: {
    pessoaId: '',
    atendimentoId: ''
  },
  detalheAgendamento: {
    id: '',
    atendimentoId: '',
    data: '',
    pessoaId: '',
    nomePessoa: '',
    cpf: '',
    horario: '',
    tipoAtendimento: '',
    tipoAtendimentoId: '',
    gravacoes: null,
    melhorTelefone: '',
    confirmado: false,
    confirmarAgendamento: false,
    atendimentoConcluidoId: null,
    observacoes: '',
    audio: false
  },
  agendaAtendimentosEventos: [],
  agendaAtendimentosData: [],
  diasSemana: {
    diasDaSemanas: [],
    proximo: '',
    anterior: ''
  },
  filtroBigNumbers: null,
  bigNumbers: {
    agendamentos: 0,
    confirmados: 0,
    naoConfirmados: 0,
    expirados: 0
  },
  visaoSupervisor: [],
  visaoCoordenador: [],
  paginacaoVisaoSupervisor: {
    page: 0,
    pageSize: 10,
    totalRegistros: 0
  },
  paginacaoVisaoCoordenador: {
    page: 0,
    pageSize: 10,
    totalRegistros: 0
  },
  dataReferencia: {
    dataFim: '',
    dataInicio: ''
  },
  tiposAgendamentos: [],
  isOpenModalReagendamento: false,
  modalReagendamento: {
    atendimentoAgendadoId: '',
    dataAtendimento: '',
    horario: '',
    tipoAtendimentoId: '',
    confirmado: false,
    confirmarAgendamento: false
  },
  modalReagendamentoInvalids: {
    atendimentoAgendadoId: {
      message: '',
      invalid: false
    },
    dataAtendimento: {
      message: '',
      invalid: false
    },
    horario: {
      message: '',
      invalid: false
    },
    tipoAtendimentoId: {
      message: '',
      invalid: false
    },
    confirmado: {
      message: '',
      invalid: false
    },
    confirmarAgendamento: {
      message: '',
      invalid: false
    }
  }
}

const reducer: Reducer<AGENDA, AnyAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.value }
    case TYPES.SET_DIA_DETALHAMENTO:
      return { ...state, diaDetalhamento: action.value }
    case TYPES.CLEAN_DIA_DETALHAMENTO:
      return { ...state, diaDetalhamento: INITIAL_STATE.diaDetalhamento }
    case TYPES.SET_AGENDAMENTO_SELECIONADO:
      return { ...state, agendamentoSelecionado: action.value }
    case TYPES.CLEAN_AGENDAMENTO_SELECIONADO:
      return {
        ...state,
        agendamentoSelecionado: INITIAL_STATE.agendamentoSelecionado
      }
    case TYPES.SET_AGENDA_ATENDIMENTOS_EVENTOS:
      return { ...state, agendaAtendimentosEventos: action.value }
    case TYPES.CLEAN_AGENDA_ATENDIMENTOS_EVENTOS:
      return {
        ...state,
        agendaAtendimentosEventos: INITIAL_STATE.agendaAtendimentosEventos
      }
    case TYPES.SET_AGENDA_ATENDIMENTOS_DATA:
      return { ...state, agendaAtendimentosData: action.value }
    case TYPES.CLEAN_AGENDA_ATENDIMENTOS_DATA:
      return {
        ...state,
        agendaAtendimentosData: INITIAL_STATE.agendaAtendimentosData
      }
    case TYPES.SET_DETALHE_AGENDAMENTO:
      return { ...state, detalheAgendamento: action.value }
    case TYPES.CLEAN_DETALHE_AGENDAMENTO:
      return { ...state, detalheAgendamento: INITIAL_STATE.detalheAgendamento }
    case TYPES.SET_DIAS_SEMANA:
      return { ...state, diasSemana: action.value }
    case TYPES.CLEAN_DIAS_SEMANA:
      return { ...state, diasSemana: INITIAL_STATE.diasSemana }
    case TYPES.SET_FILTRO_BIGNUMBERS:
      return { ...state, filtroBigNumbers: action.value }
    case TYPES.CLEAN_FILTRO_BIGNUMBERS:
      return { ...state, filtroBigNumbers: INITIAL_STATE.filtroBigNumbers }
    case TYPES.SET_BIGNUMBERS:
      return { ...state, bigNumbers: action.value }
    case TYPES.CLEAN_BIGNUMBERS:
      return { ...state, bigNumbers: INITIAL_STATE.bigNumbers }
    case TYPES.SET_PAGINACAO_VISAO_SUPERVISOR:
      return { ...state, paginacaoVisaoSupervisor: action.value }
    case TYPES.SET_VISAO_SUPERVISOR:
      return { ...state, visaoSupervisor: action.value }
    case TYPES.CLEAN_VISAO_SUPERVISOR:
      return { ...state, visaoSupervisor: INITIAL_STATE.visaoSupervisor }
    case TYPES.SET_PAGINACAO_VISAO_COORDENADOR:
      return { ...state, paginacaoVisaoCoordenador: action.value }
    case TYPES.SET_VISAO_COORDENADOR:
      return { ...state, visaoCoordenador: action.value }
    case TYPES.CLEAN_VISAO_COORDENADOR:
      return { ...state, visaoCoordenador: INITIAL_STATE.visaoCoordenador }
    case TYPES.SET_DATA_REFERENCIA:
      return { ...state, dataReferencia: action.value }
    case TYPES.CLEAN_DATA_REFERENCIA:
      return { ...state, dataReferencia: INITIAL_STATE.dataReferencia }
    case TYPES.SET_IS_OPEN_MODAL_REAGENDAMENTO:
      return { ...state, isOpenModalReagendamento: action.value }
    case TYPES.SET_MODAL_REAGENDAMENTO:
      return { ...state, modalReagendamento: action.value }
    case TYPES.SET_MODAL_REAGENDAMENTO_FIELDS:
      return {
        ...state,
        modalReagendamento: {
          ...state.modalReagendamento,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_MODAL_REAGENDAMENTO:
      return { ...state, modalReagendamento: INITIAL_STATE.modalReagendamento }
    case TYPES.SET_MODAL_REAGENDAMENTO_INVALIDS_FIELDS:
      return {
        ...state,
        modalReagendamentoInvalids: {
          ...state.modalReagendamentoInvalids,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_MODAL_REAGENDAMENTO_INVALIDS:
      return {
        ...state,
        modalReagendamentoInvalids: INITIAL_STATE.modalReagendamentoInvalids
      }
    case TYPES.SET_TIPOS_AGENDAMENTOS:
      return {
        ...state,
        tiposAgendamentos: action.value
      }
    default:
      return state
  }
}

export default reducer
